<template>
  <div class="">
    <v-navigation-drawer app class="ma-0 pa-0" v-model="mostrar">
      <opciones tipo="listado" />
    </v-navigation-drawer>
    <v-app-bar app class="mb-0 pb-0">
      <v-app-bar-nav-icon @click="mostrar = !mostrar"></v-app-bar-nav-icon>
      <h3 color="white--text">LISTADO</h3>
      <v-btn color="success" to="/cotizacion" class="ml-auto" outlined small>
        NUEVA
      </v-btn>
    </v-app-bar>
    <v-container elevation="10" fluid>
      <!-- CONTENIDO -->

      <tabla />
      <v-dialog v-model="dialogLlamadas" max-width="500px" persistent>
        <RegistroLllamda />
      </v-dialog>
      <!-- CONTENIDO -->
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import opciones from "@/components/comun/opciones.vue";

export default {
  components: {
    RegistroLllamda: () =>
      import("@/components/comun/registroLlamadaComponent.vue"),
    tabla: () =>
      import("@/components/listadoCotizacion/listarCotizacionComponents.vue"),
    opciones,
  },
  name: "resumenCotizacionView",
  data() {
    return {
      mostrar: false,
      opcionesMenu: null,
      tablaFlag: {
        tabla: true,
        rangoDias: false,
      },
      tab: null,
      items: ["Tabla", "STATUS COT X RANGO DIAS", "videos", "images", "news"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },

  computed: {
    ...mapState(["titulo", "dialogLlamadas"]),
  },
  beforeMount() {
    // this.getQuoteList();
    this.getQuoteCall({
      id_estado: null,
      id_sentido: null,
      id_carga: null,
      id_incoterms: null,
      desde: null,
      hasta: null,
    });

    this.getQuoteStatus();
    this.getModality();
    this.getShipment();
    this.getIncoterms();
    this.getMarketingList();
    this.getEntities();
  },
  mounted() {
    this.opcioneMenuLista(this.opcionesMenu);
    this.$store.state.dialogLlamadas = false;
  },
  methods: {
    ...mapMutations(["opcioneMenuLista", "setPrecotizarFlag"]),
    ...mapActions([
      "setPrecotizarFlag",
      "getQuoteStatus",
      "getModality",
      "getShipment",
      "getIncoterms",
      "getMarketingList",
      "getEntities",
      "getQuoteList",
      "getQuoteCall",
    ]),
    cambiarStep(i) {
      switch (i) {
        case 1:
          this.tablaFlag.tabla = true;
          this.tablaFlag.rangoDias = false;
          break;
        case 2:
          this.tablaFlag.tabla = false;
          this.tablaFlag.rangoDias = true;
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scope>
html {
  scroll-behavior: smooth;
}

/* .v-tab {
  transform: scale(1.1);
} */
</style>
